import {IconButton} from '@tempo/core';
import type {IconButtonProps} from '@tempo/core';
import {ChevronLeftIcon} from '@tempo/icons';
import {useRouter} from 'next/router';
import React from 'react';

type BackButtonProps = Omit<IconButtonProps, 'aria-label'>;

const BackButton: React.FC<BackButtonProps> = ({children, ...rest}) => {
	const router = useRouter();

	return (
		<IconButton
			icon={<ChevronLeftIcon color="icon.neutral.primary.default" />}
			onClick={router.back}
			aria-label="BackButton"
			variant="ghost"
			mr={2}
			{...rest}
		/>
	);
};

BackButton.displayName = 'NavBar.BackButton';

export default BackButton;
