import config from '@modules/config';

export const getLibraryTransferUrl = async (
	userId: string,
	userName: string,
	token: string,
	redirectPath: string,
): Promise<string> => {
	const WEBSITE_REDIRECT_URL = `https://${window.location.hostname}${redirectPath}`;

	const tmmResponse = await fetch(config.get('tmmApiUrl'), {
		method: 'POST',
		cache: 'default',
		body: JSON.stringify({
			platform: 'Deezer',
			deezer_access_token: token,
			deezer_user_id: userId,
			deezer_user_name: userName,
			mode: 'shaker',
			deezer_redirect_url: WEBSITE_REDIRECT_URL,
		}),
	}).catch((e) => {
		throw e;
	});

	if (!tmmResponse.ok || tmmResponse.status !== 200) {
		throw new Error(`TMM: response error`);
	}

	const tmmData = await tmmResponse.json().catch((e) => {
		throw e;
	});

	try {
		const migrationUrl = new URL(tmmData.migration_url);
		migrationUrl.searchParams.append('includePlaylists', 'true');
		migrationUrl.searchParams.append('transferInBackground', 'true');

		return migrationUrl.href;
	} catch (e) {
		throw new Error('TMM: migration url not valid');
	}
};
