import React from 'react';
interface LogoProps {
	color: 'black' | 'white';
	width: string;
	height: string;
}

const Logo = ({color, width, height}: LogoProps) => {
	return (
		<svg
			fill={color}
			width={width}
			height={height}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 243 175"
		>
			<path d="M24.947 48.463c-13.467 4.246-16.576 33.155-6.945 64.57 9.632 31.415 28.357 53.44 41.824 49.195 13.467-4.246 16.577-33.155 6.945-64.57-9.631-31.416-28.357-53.44-41.824-49.195Z" />
			<ellipse
				cx="20.117"
				cy="19.992"
				rx="20.117"
				ry="19.992"
				transform="rotate(4.438 -1444.29 2672.468) skewX(-.056)"
			/>
			<path d="M129.625 162.283c-17.617 7.023-45.662-20.764-62.64-62.066-16.979-41.301-16.462-80.477 1.155-87.5 17.617-7.023 45.662 20.764 62.64 62.066 16.979 41.301 16.462 80.477-1.155 87.5Z" />
			<path d="M161.066 161.694c-11.118.357-21.089-28.283-22.272-63.97-1.183-35.687 6.871-64.906 17.989-65.263 11.118-.358 21.089 28.282 22.272 63.97 1.183 35.686-6.871 64.906-17.989 65.263Z" />
			<path d="M186.903 162.842c-4.058.131-7.828-14.229-8.419-32.072-.591-17.843 2.219-32.414 6.278-32.545 4.058-.13 7.828 14.229 8.419 32.073.591 17.843-2.219 32.414-6.278 32.544Z" />
		</svg>
	);
};

export default Logo;
