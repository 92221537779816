import React from 'react';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';

import {IconButton, useDisclosure} from '@tempo/core';
import {
	MoreHorizontalIcon,
	LogoutIcon,
	NotePlusIcon,
	CrossIcon,
	HumanOutlinedIcon,
} from '@tempo/icons';

import {CustomEventActions} from '@lib/logcenter/eventLog';

import {useUser} from '@providers/UserProvider';
import {useBridge} from '@providers/BridgeProvider';
import {useLogEvent} from '@providers/LogEventProvider';
import useTmmRedirect from '@hooks/useTmmRedirect';
import {useUser as useUserLegacy} from '@deezer/react-user';

import BottomSheet from '@components/BottomSheet';
import NavBar from '@components/NavBar';
import ShakerLogo, {type LogoColor} from '@components/ShakerLogo';
import {HEADER_SIZE} from '@components/BottomSheet/Header';

import config from '@modules/config';

type NavBarBrandProps = {
	locale: string;
	background?: string;
	logoColor?: LogoColor;
};

const NavBarBrand: React.FC<NavBarBrandProps> = ({
	locale,
	background,
	logoColor,
}) => {
	const {user} = useUser();
	const router = useRouter();
	const {t} = useTranslation();
	const [userLegacy] = useUserLegacy();
	const fetchTmm = useTmmRedirect();
	const {eventLogs} = useLogEvent();
	const accountWebsiteSsl = config.get('accountWebsiteSsl');
	const bridge = useBridge();

	const {
		isOpen: isBottomSheetOpen,
		onOpen: onOpenBottomSheet,
		onClose: onCloseBottomSheet,
	} = useDisclosure();

	const redirectToAccount = () => {
		router.push(`${accountWebsiteSsl}/${locale}/shaker/account`);
	};

	const logout = () => {
		eventLogs?.logCustomEvent({
			action: CustomEventActions.logOut,
		});
		router.push(`${accountWebsiteSsl}/${locale}/shaker/logout?path=/`);
	};

	const importTMM = () => {
		fetchTmm(userLegacy, '/groups').then((url) => {
			if (url) router.push(url);
		});
	};

	return (
		<NavBar justify="center" position="relative" bg={background}>
			{bridge && (
				<IconButton
					aria-label={t('generic_action_close_web')}
					data-testid="exit-cross"
					onClick={() => bridge.closeScreen()}
					position="absolute"
					insetStart={0}
					icon={<CrossIcon size="small" />}
					top="50%"
					transform="translateY(-50%)"
					variant="ghost"
				/>
			)}
			<ShakerLogo color={logoColor} />
			{user && (
				<>
					<IconButton
						position="absolute"
						right={0}
						icon={<MoreHorizontalIcon color="icon.neutral.primary.default" />}
						aria-label="user menu"
						variant="ghost"
						onClick={onOpenBottomSheet}
					/>
					<BottomSheet onClose={onCloseBottomSheet} isOpen={isBottomSheetOpen}>
						<BottomSheet.Header size={HEADER_SIZE.S}>
							<BottomSheet.Header.Title>{user.name}</BottomSheet.Header.Title>
						</BottomSheet.Header>

						<BottomSheet.Button
							onClick={redirectToAccount}
							icon={<HumanOutlinedIcon />}
						>
							{t('accountsettingspage_title_accountsettings_web')}
						</BottomSheet.Button>

						<BottomSheet.Button onClick={importTMM} icon={<NotePlusIcon />}>
							{t('musictogether_action_reimportlibrary_web')}
						</BottomSheet.Button>

						<BottomSheet.Button
							as="a"
							href="https://www.deezer.com/legal/cgu"
							target="blank"
							icon={<LogoutIcon />}
						>
							{t('footer_title_legal_web')}
						</BottomSheet.Button>

						<BottomSheet.Button onClick={logout} icon={<LogoutIcon />}>
							{t('generic_action_logout_web')}
						</BottomSheet.Button>
					</BottomSheet>
				</>
			)}
		</NavBar>
	);
};

export default NavBarBrand;
