import {useApiCall} from '@deezer/react-legacy-api';
import {getLibraryTransferUrl} from '@lib/getLibraryTransferUrl';

const useTmmRedirect = () => {
	const apiCall = useApiCall();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return async (user: any, redirectUrl: string): Promise<string | null> => {
		const token = await apiCall({
			method: 'libraryimport.getTMMAccessToken',
			data: {},
		});

		if (!token) {
			throw new Error('Error token');
		}

		const migrationUrl = await getLibraryTransferUrl(
			user.id,
			user.blogname,
			token as string,
			redirectUrl,
		);

		if (!migrationUrl) {
			throw new Error('Error migrationUrl');
		}

		return migrationUrl;
	};
};

export default useTmmRedirect;
