import Cookies from 'js-cookie';

export type DzrUniqId = string | null;

const useDzrUniqId = (): DzrUniqId => {
	const dzrUniqId = Cookies.get('dzr_uniq_id');
	return dzrUniqId || null;
};

export default useDzrUniqId;
