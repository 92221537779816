import {Flex, Heading} from '@tempo/core';
import React from 'react';
import Logo from './Logo';

export enum LogoColor {
	BLACK = 'black',
	WHITE = 'white',
}

interface ShakerLogoProps {
	color?: LogoColor;
}

const ShakerLogo = ({color = LogoColor.WHITE}: ShakerLogoProps) => (
	<Flex color={color === LogoColor.BLACK ? '#000' : '#FFF'} align="flex-end">
		<Logo color={color} height="16px" width="24px" />
		<Heading lineHeight="1.25rem" variant="heading.brand" ml="spacing.xs">
			Shaker
		</Heading>
	</Flex>
);

export default ShakerLogo;
