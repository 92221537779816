import {Flex, type FlexProps} from '@tempo/core';
import React from 'react';

const Actions: React.FC<FlexProps> = ({children, ...rest}) => (
	<Flex direction="row" align="center" {...rest}>
		{children}
	</Flex>
);

Actions.displayName = 'NavBar.Actions';

export default Actions;
