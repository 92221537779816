import {Flex, type FlexProps} from '@tempo/core';
import React from 'react';
import BackButton from './BackButton';
import Actions from './Actions';

type SubComponents = {
	BackButton: typeof BackButton;
	Actions: typeof Actions;
};

const NavBar: React.FC<FlexProps> & SubComponents = ({children, ...rest}) => (
	<Flex
		direction="row"
		m="auto"
		align="center"
		justify="space-between"
		maxW="60ch"
		bg="background.neutral.primary.default"
		h="size.xl"
		{...rest}
	>
		{children}
	</Flex>
);

NavBar.BackButton = BackButton;
NavBar.Actions = Actions;

export default NavBar;
